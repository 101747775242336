.admin.registrations table ul {
  list-style-type: none;
  margin-left: 0;
  padding-left: 0;
}
.admin.registration .panel, .admin.registration-form .panel {
  float: left;
  margin-right: 20px;
}
.admin.registration .panel table tr td:last-child, .admin.registration-form .panel table tr td:last-child {
  text-align: right;
  padding-left: 10px;
}
.admin.registration #notes .fa-trash {
  padding-left: 5px;
}
.admin.registration #notes .summary, .admin.registration #logs .summary {
  margin-top: 10px;
}
.admin .updates {
  width: 100%;
  background-color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  top: 0;
}
.admin .stats {
  padding-bottom: 20px;
}
.admin #email-modal textarea {
  width: 100%;
  height: 300px;
}
.admin .person-summary {
  float: left;
  margin-right: 20px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcF9yb290L2FwcHMvYWRtaW4vYXBwL3ZpZXdzL2FkbWluL3JlZ2lzdHJhdGlvbnMvY3NzIiwic291cmNlcyI6WyJpbmRleC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVJO0VBQ0U7RUFDQTtFQUNBOztBQUtGO0VBQ0U7RUFDQTs7QUFFQTtFQUNFO0VBQ0E7O0FBTUo7RUFDRTs7QUFJQTtFQUNFOztBQUtOO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFHRjtFQUNFOztBQUdGO0VBQ0U7RUFDQTs7QUFHRjtFQUNFO0VBQ0EiLCJzb3VyY2VzQ29udGVudCI6WyIuYWRtaW4ge1xuICAmLnJlZ2lzdHJhdGlvbnMge1xuICAgICYgdGFibGUgdWwge1xuICAgICAgbGlzdC1zdHlsZS10eXBlOiBub25lO1xuICAgICAgbWFyZ2luLWxlZnQ6IDA7XG4gICAgICBwYWRkaW5nLWxlZnQ6IDA7XG4gICAgfVxuICB9XG5cbiAgJi5yZWdpc3RyYXRpb24sICYucmVnaXN0cmF0aW9uLWZvcm0ge1xuICAgICYgLnBhbmVsIHtcbiAgICAgIGZsb2F0OiBsZWZ0O1xuICAgICAgbWFyZ2luLXJpZ2h0OiAyMHB4O1xuXG4gICAgICAmIHRhYmxlIHRyIHRkOmxhc3QtY2hpbGQge1xuICAgICAgICB0ZXh0LWFsaWduOiByaWdodDtcbiAgICAgICAgcGFkZGluZy1sZWZ0OiAxMHB4O1xuICAgICAgfVxuICAgIH1cbiAgfVxuXG4gICYucmVnaXN0cmF0aW9uIHtcbiAgICAmICNub3RlcyAuZmEtdHJhc2gge1xuICAgICAgcGFkZGluZy1sZWZ0OiA1cHg7XG4gICAgfVxuXG4gICAgJiAjbm90ZXMsICYgI2xvZ3Mge1xuICAgICAgLnN1bW1hcnkge1xuICAgICAgICBtYXJnaW4tdG9wOiAxMHB4O1xuICAgICAgfVxuICAgIH1cbiAgfVxuXG4gICYgLnVwZGF0ZXMge1xuICAgIHdpZHRoOiAxMDAlO1xuICAgIGJhY2tncm91bmQtY29sb3I6IHdoaXRlO1xuICAgIHBhZGRpbmctdG9wOiAxMHB4O1xuICAgIHBhZGRpbmctYm90dG9tOiAxMHB4O1xuICAgIHRvcDogMDtcbiAgfVxuXG4gICYgLnN0YXRzIHtcbiAgICBwYWRkaW5nLWJvdHRvbTogMjBweDtcbiAgfVxuXG4gICYgI2VtYWlsLW1vZGFsIHRleHRhcmVhIHtcbiAgICB3aWR0aDogMTAwJTtcbiAgICBoZWlnaHQ6IDMwMHB4O1xuICB9XG5cbiAgJiAucGVyc29uLXN1bW1hcnkge1xuICAgIGZsb2F0OiBsZWZ0O1xuICAgIG1hcmdpbi1yaWdodDogMjBweDtcbiAgfVxufVxuIl19 */